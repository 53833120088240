Math.randomInRange = (min, max) ->
  Math.floor(Math.random()*(max-min+1)+min)

modules = [
]

angular.module 'Polyglot', modules

angular.module('Polyglot').directive "pgWheat", ->
  restrict: "EA"
  replace: true
  template: """
    <div class="pg-wheat">
      <div
        ng-repeat="stock in stocks"
        class="stock"
        ng-style="stock"
        ></div>
    </div>
  """
  controller: ($scope, $window) ->
    $scope.stocks = []

    density = 60
    stockCount = $window.innerWidth / density

    for stockNumber in [0..stockCount]
      tilt = Math.randomInRange -22, 22
      offset = Math.randomInRange -15, 15
      margin = 20

      $scope.stocks.push
        margin: "0 #{margin}px"
        transform: "rotate(#{tilt}deg) translateY(#{offset}px)"

angular.module('Polyglot').directive "pgSection", ->
  restrict: "EA"
  replace: true
  transclude: true
  template: """
    <section>
      <div class="content" ng-transclude></div>
    </section>
  """

angular.module('Polyglot').directive "pgTickets", ->
  restrict: "EA"
  replace: true
  template: """
    <center>
      <a class="button" href="https://www.eventbrite.ca/e/polyglot-alberta-2015-tickets-15716920733" target="_blank">Get Tickets Now</a>
    </center>
  """